import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { getLang } from '../../redux/global/globalSelectors';
import routesPaths from '../../routes/routesPaths'
import routes from '../../routes/routes'
import { GlobalContentContainer, SectionText } from '../../App.styled'
// import Language from "../Language";
import { socialLinks, appStoreLink, googlePlayLink } from '../../helpers/consts'
import {
  FooterContainer,
  BottomContent,
  BottomInfoText,
  BottomLink,
  MainContent,
  LogoLink,
  Logo,
  LogoTitle,
  ContactsContainer,
  ContactsTitle,
  ContactsLink,
  // LangContainer,
  // LangText,
  SocialLinks,
  FooterLeftContent,
  PageContactsContainer,
  PageContactColumn,
  BottomInnerLink,
  AppsContainer,
  Apps,
  App,
  AddressText
 } from './styled'
 import { siteName, 
  // cookiesLink, legalLink, privacyPolicyLink
 } from '../../helpers/consts'

 import logoImg from "../../images/UGPay_Logo.svg";
 import { ReactComponent as IstagramIcon } from '../../images/links/instagram.svg'
 import { ReactComponent as TelegramIcon } from '../../images/links/telegram.svg'
//  import { ReactComponent as VkIcon } from '../../images/links/vk.svg'
//  import { ReactComponent as FacebookIcon } from '../../images/links/facebook.svg'
 import { ReactComponent as TwitterIcon } from '../../images/links/twitter.svg'
 import { ReactComponent as LinkedinIcon } from '../../images/links/linkedin.svg'
 import { ReactComponent as YoutubeIcon } from '../../images/links/youtube.svg'

 import { ReactComponent as AppStoreIcon } from '../../images/icons/appstore.svg'
 import { ReactComponent as GooglePlayIcon } from '../../images/icons/googleplay.svg'

 const bottomLinks = [
   { text: 'FOOTER.REG_DOCS',
     link: '/legal' },
   { text: 'FOOTER.POLICY_CONF',
     link: '/legal/privacy-policy'},
   { text: 'FOOTER.COOKIES',
     link: '/legal/cookie-statement'},
 ]

const socials = [
  { svg: <IstagramIcon />, link: socialLinks.instagram },
  { svg: <TelegramIcon />, link: socialLinks.telegram },
  { svg: <TwitterIcon />, link: socialLinks.twitter },
  { svg: <LinkedinIcon />, link: socialLinks.linkedin },
  { svg: <YoutubeIcon />, link: socialLinks.youtube },
]

export default function Footer() {
  const { t } = useTranslation()
  const currentLang = useSelector(getLang)

  return (
    <FooterContainer>
      <GlobalContentContainer>
        <MainContent>
          <FooterLeftContent>
            <LogoLink to={routesPaths.main}>
              <Logo src={logoImg} />
              <LogoTitle>{siteName}</LogoTitle>
            </LogoLink>

            <SectionText>{t('FOOTER.INFO')}</SectionText>
            <SectionText style={{marginTop: '0.6rem'}}>{t('FOOTER.INFO_2')}</SectionText>

            <AppsContainer>
              {/* <ContactsTitle>{t('FOOTER.APPS')}</ContactsTitle> */}
              <Apps>
                <App href={appStoreLink}><AppStoreIcon /></App>
                <App href={googlePlayLink}><GooglePlayIcon /></App>
              </Apps>
            </AppsContainer>

            <ContactsContainer>
              <ContactsTitle>{t('FOOTER.CONTACTS')}</ContactsTitle>
              <AddressText>
                GUP-CH AG is registered with the Swiss Companies Register (company number CHE-114.304.932)
                with a registered address at Chaltenbodenstrasse 16, 8834 Schindellegi, Switzerland.
              </AddressText>
              <SocialLinks>
                {socials.map(({ svg, link }) => <ContactsLink key={link.en} href={link[currentLang]}>{svg}</ContactsLink>)}
                {/* {currentLang !== 'ru' && <ContactsLink key={socialLinks.facebook.en} href={socialLinks.facebook.en}><FacebookIcon /></ContactsLink>} */}
              </SocialLinks>
            </ContactsContainer>
          </FooterLeftContent>

          <PageContactsContainer>
            {routes.filter(({ withSubMenu }) => withSubMenu).map(({ label, key, subMenu }) =>
            <PageContactColumn key={key}>
              <ContactsTitle>{t(label)}</ContactsTitle>
              {subMenu.map(el => <BottomInnerLink to={el.path} key={el.key}>{t(el.label)}</BottomInnerLink>)}
            </PageContactColumn>
            )}
            <PageContactColumn>
              <ContactsTitle>{t('FOOTER.DOCUMENTS')}</ContactsTitle>
              {bottomLinks.map(({ text, link }) => <BottomLink key={text} to={link}>{t(text)}</BottomLink> )}
            </PageContactColumn>
          </PageContactsContainer>
        </MainContent>

        <BottomContent>
          <BottomInfoText>
            The trading of goods and products, real or virtual, as well as virtual currencies, involves significant risk. The value of cryptocurrencies can be highly volatile, with the potential for significant loss. Some crypto products and markets are unregulated, and you may not be protected by government compensation and/or regulatory protection schemes. The unpredictable nature of crypto-asset markets can lead to the loss of funds. Tax may be payable on any returns and/or any increase in the value of your crypto assets, and you should seek independent advice on your taxation position. Geographic restrictions may apply.
          </BottomInfoText>
          <BottomInfoText>
            GUP-CH AG does not market to or serve clients from the United Kingdom. This website and its content are intended for people outside of the United Kingdom. The information provided is not directed at or intended for distribution to, or use by anyone located within the UK. The financial products and services mentioned on this website are not eligible for the UK.
          </BottomInfoText>
          <BottomInfoText>© 2024 GUP-CH AG</BottomInfoText>
          {/*<LangContainer>*/}
          {/*  <LangText>{t('FOOTER.LANG')}</LangText>*/}
          {/*  <Language isBottom />*/}
          {/*</LangContainer>*/}
        </BottomContent>
      </GlobalContentContainer>
    </FooterContainer>
  );
}
